var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container--fluid pt-3",attrs:{"id":"report_member"}},[_c('v-card',[_c('v-card-title',[_vm._v(" รายงานข้อมูลสมาชิกพ้นสภาพ "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{attrs:{"color":"gray"},on:{"click":_vm.onExport}},[_vm._v("Export")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":this.$store.getters.getlistMemberLose,"search":_vm.search},scopedSlots:_vm._u([{key:"item.mem_birthday_th",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("ConvDateToDMY_TH")(item.mem_birthday))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.sm_type_eject == '3')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer")]),_vm._v(" เอกสาร "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","target":"_blank","href":'https://www.maeainetworkcommittee.com/report/CreatePDF/examples/main_report3.php?mem_id=' +
                item.mem_id}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-adobe-acrobat")]),_vm._v("แบบขอรับเงินสงเคราะห์ ")],1)],1),_c('v-list-item',{attrs:{"link":"","target":"_blank","href":'https://www.maeainetworkcommittee.com/report/CreatePDF/examples/main_report5.php?mem_id=' +
                item.mem_id}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-adobe-acrobat")]),_vm._v("แบบรับเงินสงเคราะห์ ")],1)],1),_c('v-list-item',{attrs:{"link":"","target":"_blank","href":'https://www.maeainetworkcommittee.com/report/CreatePDF/examples/main_report2.php?mem_id=' +
                item.mem_id}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-adobe-acrobat")]),_vm._v("แบบจ่ายเงินสงเคราะห์ ")],1)],1)],1)],1):_c('div',[_vm._v("-")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }