<template>
  <div id="report_member" class="container--fluid pt-3">
    <v-card>
      <v-card-title>
        รายงานข้อมูลสมาชิกพ้นสภาพ
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="gray" @click="onExport">Export</v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.getters.getlistMemberLose"
        :search="search"
      >
        <template v-slot:[`item.mem_birthday_th`]="{ item }"
          >{{ item.mem_birthday | ConvDateToDMY_TH }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y v-if="item.sm_type_eject == '3'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on"
                ><v-icon left>mdi-printer</v-icon> เอกสาร
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                target="_blank"
                :href="
                  'https://www.maeainetworkcommittee.com/report/CreatePDF/examples/main_report3.php?mem_id=' +
                  item.mem_id
                "
              >
                <v-list-item-title
                  ><v-icon left>mdi-adobe-acrobat</v-icon>แบบขอรับเงินสงเคราะห์
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                target="_blank"
                :href="
                  'https://www.maeainetworkcommittee.com/report/CreatePDF/examples/main_report5.php?mem_id=' +
                  item.mem_id
                "
              >
                <v-list-item-title
                  ><v-icon left>mdi-adobe-acrobat</v-icon>แบบรับเงินสงเคราะห์
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                target="_blank"
                :href="
                  'https://www.maeainetworkcommittee.com/report/CreatePDF/examples/main_report2.php?mem_id=' +
                  item.mem_id
                "
              >
                <v-list-item-title
                  ><v-icon left>mdi-adobe-acrobat</v-icon>แบบจ่ายเงินสงเคราะห์
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-else>-</div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "report_member",
  data() {
    return {
      desserts: [],
      search: "",
      headers: [
        { text: "รหัสสมาชิก", value: "mem_id" },
        { text: "เลขบัตรประชาชน", value: "mem_people_id" },
        { text: "ชื่อ-นามสกุล", value: "mem_fullname" },
        { text: "หมู่บ้าน", value: "vil_name" },
        { text: "ที่อยู่", value: "mem_address" },
        { text: "ตำบล", value: "LocName" },
        { text: "อำเภอ", value: "DisName" },
        { text: "จังหวัด", value: "ProName" },
        { text: "เบอร์โทร", value: "mem_phone" },
        { text: "วันเดือนปีเกิด", value: "mem_birthday_th" },
        { text: "วันที่สมัคร", value: "mem_regis_date_th" },
        { text: "วันที่พ้นสภาพ", value: "sm_date_eject_th" },
        { text: "สาเหตุ", value: "sm_type_name" },
        { text: "ผู้รับผลประโยชน์", value: "mem_receipt" },
        { text: "เอกสาร", value: "actions" },
      ],
    };
  },
  methods: {
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.$store.getters.getlistMemberReport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "report_member_lose.xlsx");
    },
  },
  mounted() {
    this.$store.dispatch("getlistMemberLose");
  },
  filters: {
    ConvDateToDMY_TH: (dateTxt) => {
      let dtArr = dateTxt.split("-");
      return dtArr[2] + "/" + dtArr[1] + "/" + (parseInt(dtArr[0]) + 543).toString();
    },
  },
};
</script>

<style></style>
